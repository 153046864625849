<template>
  <div class="container-wrapper">
    <el-row class="main-header" :gutter="20">
      <el-col :span="11">
        <el-date-picker
          style="width: 100%"
          v-model="timeScope"
          type="datetimerange"
          :range-separator="$t('userinfoViewSms.to')"
          :start-placeholder="$t('userinfoViewSms.startDate')"
          :end-placeholder="$t('userinfoViewSms.endDate')"
          @change="searchEvent"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="7">
        <el-input :placeholder="$t('userinfoViewSms.enterSearch')" :title="query ? '' : $t('userinfoViewSms.enterSearch')" v-model="query" @keyup.enter.native="searchEvent" clearable>
          <el-button icon="el-icon-search" @click="searchEvent" slot="append"></el-button>
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-select v-model="searchstatus" placeholder="" @change="searchEvent">
          <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-col>
    </el-row>
    <div ref="tableWrapper">
      <el-table ref="tableRef" :data="smsList" style="width: 100%" height="410">
        <el-table-column prop="pedesc" min-width="15%" :label="$t('userinfoViewSms.smsPeDesc')">
          <template slot-scope="{ row }">
            <span class="text-line-1" :title="row.pedesc">{{ row.pedesc }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="content" min-width="25%" :label="$t('userinfoViewSms.smsContent')">
          <template slot-scope="{ row }">
            <span class="text-line-1" :title="row.content">{{ row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="senttime" min-width="20%" :label="$t('userinfoViewSms.smsSendTime')">
          <template slot-scope="scope">
            {{ $utils.format(new Date(scope.row.senttime), 'Y-m-d H:i:s') }}
          </template>
        </el-table-column>
        <el-table-column prop="isvoice" min-width="15%" :label="$t('userinfoViewSms.smsIsVoiceLabel')">
          <template slot-scope="scope">
            {{ scope.row.isvoice ? $t('common.yes') : $t('common.no') }}
          </template>
        </el-table-column>
        <el-table-column prop="status" min-width="10%" :label="$t('userinfoViewSms.status')">
          <template slot-scope="scope">
            {{ scope.row.status == '1' ? $t('userinfoViewSms.success') : $t('userinfoViewSms.failure') }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.operation')" min-width="10%">
          <template slot-scope="scope">
            <el-button @click="viewClick(scope.row)" type="text" icon="el-icon-asset-detail"></el-button>
          </template>
        </el-table-column>
        <div slot="empty">
          <el-empty :description="$t('userinfoViewSms.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
        </div>
      </el-table>
      <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getSmsinfodetail"></pagination-box>
    </div>

    <!-- 查看短信详情 -->
    <el-dialog top="40px" :title="$t('userinfoViewSms.detailTitle')" :visible.sync="dialogVisible" destroy-on-close width="650px" append-to-body>
      <el-form label-width="160px" v-if="dialogVisible">
        <el-form-item :label="$t('userinfoViewSms.smsPeDesc') + ':'">
          {{ viewData.pedesc }}
        </el-form-item>
        <el-form-item :label="$t('userinfoViewSms.smsIsVoiceLabel') + ':'">
          {{ viewData.isvoice ? $t('common.yes') : $t('common.no') }}
        </el-form-item>
        <el-form-item :label="$t('userinfoViewSms.status') + ':'">
          {{ viewData.status == '1' ? $t('userinfoViewSms.success') : $t('userinfoViewSms.failure') }}
        </el-form-item>

        <el-form-item :label="$t('userinfoViewSms.smsErrorMsgLabel') + ':'">
          {{ getSmsErrorMsg(viewData.errormsg) }}
        </el-form-item>
        <el-form-item :label="$t('userinfoViewSms.smsSendName') + ':'">
          {{ viewData.sendername }}
        </el-form-item>
        <el-form-item :label="$t('userinfoViewSms.smsSendPhone') + ':'">
          {{ viewData.phone }}
        </el-form-item>
        <el-form-item :label="$t('userinfoViewSms.smsSendTime') + ':'">
          {{ $utils.format(new Date(viewData.senttime), 'Y-m-d H:i:s') }}
        </el-form-item>
        <el-form-item :label="$t('userinfoViewSms.smsContent') + ':'">
          {{ viewData.content }}
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
