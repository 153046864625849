

export default {
  name: 'UserinfoViewSmsPage',
  data() {
    return {
      // 短信列表数据
      smsList: [],
      // 翻页配置
      pagesObj: {
        page: 1,
        pagesize: 10,
        total: 0
      },
      // 搜索条件
      query: '',
      // 搜索状态
      searchstatus: '',
      // 时间范围
      timeScope: [],
      // 状态选项
      statusOption: [
        {
          label: this.$t('common.all'),
          value: '',
        },
        {
          label: this.$t('userinfoViewSms.success'),
          value: '1',
        },
        {
          label: this.$t('userinfoViewSms.failure'),
          value: '0',
        },
      ],
      // 详情弹窗
      dialogVisible: false,
      // 详情数据
      viewData: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getSmsinfodetail()
    })



  },
  methods: {
    //sms列表
    getSmsinfodetail(page) {
      if (page) {
        this.pagesObj.page = page
      } else {
        this.pagesObj.page = 1
      }
      this.$http.post('/home/smsinfodetail', {
        hrid: this.$store.getters.loginid,
        penum: this.$store.getters.penum,
        "query": this.query,
        page: this.pagesObj.page,
        pagesize: this.pagesObj.pagesize,
        searchsenttimestart: this.timeScope ? this.timeScope[0] : '',
        searchsenttimeend: this.timeScope ? this.timeScope[1] : '',
        searchstatus: this.searchstatus,
      }, {
        loadTarget: this.$refs.tableWrapper
      }).then(response => {
        // 更新数据
        if (response.data.success) {
          this.smsList = response.data.content;
          this.pagesObj = {
            ...this.pagesObj,
            // page: response.data.page,
            total: response.data.total
          }

        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    // 搜索
    searchEvent() {
      this.getSmsinfodetail()
    },
    // 查看详情
    viewClick(row) {
      this.viewData = row
      console.log('this.viewData', this.viewData);

      this.dialogVisible = true
    },
    // 获取短信错误信息的枚举
    getSmsErrorMsg(errorCode) {
      if (errorCode) {
        return this.$t(`common.${errorCode}`).indexOf('common.') === -1 ? this.$t(`common.${errorCode}`) : errorCode
      } else {
        return ''
      }
    }
  }
}