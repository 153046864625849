export default {
	name: 'UserinfoEditPhonePage',
	data() {
		const checkPassword = (rule, value, callback) => {
			if (value == this.submitData.npassword) {
				return callback();
			} else {
				return callback(new Error(this.$t('userinfoEditPhone.repassTips')));
			}
		}
		const checkPhone = (rule, value, callback) => {
			this.isChangephoneSuccess = false
			this.$http
				.post('/common/checkphone', {
					phone: value,
					type: '4',
					langcode: this.$store.getters.lang,
					areacode: this.submitData.areacode,
				}, {
					timeout: 60000,
					showLoading: false
				})
				.then((response) => {
					if (response.data.success) {
						this.isChangephoneSuccess = true
						return callback();
					} else {

						return callback(new Error(response.data.message));
					}
				})
				.catch((error) => {
					console.log(error);

					return callback(new Error(error));
				});
		}
		return {
			// 表单数据
			submitData: {
				areacode: '',
				changephone: '',
				opassword: '',
				npassword: '',
				repwd: '',
				imgCode: '',
				code: ''
			},
			// 校验规则
			submitRules: {
				changephone: [
					{ required: true, message: this.$t('userinfoEditPhone.correctPhoneNum'), trigger: 'blur' },
					{ validator: checkPhone, trigger: 'blur' }
				],
				opassword: [
					{ required: true, pattern: /^[a-zA-Z0-9@#&*_]{6,20}$/, message: this.$t('userinfoEditPhone.configNewPassFormat'), trigger: 'blur' },
				],
				npassword: [
					{ required: true, pattern: /^[a-zA-Z0-9@#&*_]{6,20}$/, message: this.$t('userinfoEditPhone.configNewPassFormat'), trigger: 'blur' },
				],
				repwd: [
					{ validator: checkPassword, trigger: 'blur' }
				],
				imgCode: [
					{ required: true, message: this.$t('userinfoEditPhone.correctImageCode'), trigger: 'blur' },
				],
				code: [
					{ required: true, message: this.$t('userinfoEditPhone.correctCode'), trigger: 'blur' },
				]
			},
			// 区号
			countryList: [
				{
					id: '86',
					name: 'CN +86',
				},
				{
					id: '852',
					name: 'HK +852',
				},
				{
					id: '853',
					name: 'MO +853',
				},
				{
					id: '886',
					name: 'TW +886',
				},
				{
					id: '60',
					name: 'MAS +60',
				},
				{
					id: '65',
					name: 'SG +65',
				},
				{
					id: '61',
					name: 'AU +61',
				},
			],
			// 自定义区号
			inputAreacodeList: [],
			inputAreacodeShow: false,
			countryVal: '',
			// 验证码图片地址
			imgCodeSrc: '',
			imgcodekey: '',
			// 手机验证码时间事件
			timer: null,
			// 验证码倒计时
			wait: 60,
			// 发送验证码按钮文字
			sendCodeText: this.$t('userinfoEditPhone.getcodeBtn'),
			// 检查号码是否通过校验
			isChangephoneSuccess: false,
			// 手机验证码发送状态
			isSendPhone: false,
			submitBtnDisabled: true,
		}
	},
	watch: {
		// 监听表单禁用按钮
		submitData: {
			handler: function (newVal) {
				let values = []
				for (const key in newVal) {
					values.push(newVal[key])
				}
				this.submitBtnDisabled = !values.every(Boolean)
			}, deep: true
		}
	},
	created() {
		this.$nextTick(() => {
			this.getImageCode()
			this.submitData.areacode = this.countryList[0].id
		})

	},
	methods: {
		// 只输入数字
		handleNumInput(value) {
			/* eslint-disable */
			const reg = /^[\d\.]+$/;
			if (value && !reg.test(value)) {
				this.submitData.changephone = value.replace(/[^\d\.]/g, '');
			}
		},
		// 发送验证码
		getCode() {
			this.$refs.editPhoneForm.validateField('imgCode', (valid) => {
				if (!valid) {
					if (this.wait === 60) {
						this.$http
							.post('/common/gencode', {
								areacode: this.submitData.areacode,
								phone: this.submitData.changephone,
								type: '4',
								langcode: this.$store.getters.lang,
								code: this.submitData.imgCode,
								key: this.imgcodekey,
							})
							.then((response) => {
								if (response.data.success) {
									this.$message({
										type: 'success',
										message: this.$t('userinfoEditPhone.phoneSendSuccess')
									});
									this.isSendPhone = true
									this.timer = setInterval(() => {
										// this.istimer = true;
										this.wait = this.wait - 1;
										this.sendCodeText = this.$t('userinfoEditPhone.secgetcodeBtn', { second: this.wait })
										// this.codeshow = _this.wait + secgetcode;
										// this.iscode = true;
										if (this.wait === 0) {
											clearInterval(this.timer);
											// this.istimer = false;
											this.isSendPhone = false
											this.sendCodeText = this.$t('userinfoEditPhone.getcodeBtn')
											this.wait = 60;
											// this.iscode = false;
										}
									}, 1000);
									return;
								} else {
									this.submitData.imgCode = ''
									this.getImageCode()
									if (this.$t(`common.${response.data.message}`).indexOf('common.') === -1) {
										if (response.data.message === 'ERR_NOTICE_0001') {
											this.$alert('+' + this.submitData.areacode + this.submitData.changephone + this.$t(`common.${response.data.message}`), this.$t('common.alertPrompt'), {
												type: 'error',
												center: true
											})
										} else {
											this.$alert(this.$t(`common.${response.data.message}`), this.$t('common.alertPrompt'), {
												type: 'error',
												center: true
											})
										}
									} else {
										this.$alert(response.data.message, this.$t('common.alertPrompt'), {
											type: 'error',
											center: true
										})
									}
								}
							})


					}

				} else {
					// console.log('error submit!!');
					return false;
				}
			})
			// console.log()

		},
		// 获取验证码
		getImageCode() {
			this.$http.post('/common/genimgcode').then((response) => {
				if (response.data.success) {
					// this.imgcode = response.data.content.code;
					this.imgCodeSrc = response.data.content.imagesrc;
					this.imgcodekey = response.data.content.key;
				}
			})
		},
		// 选择区号
		handelChangeAreacode(val) {
			if (val === 'more') {
				this.inputAreacodeShow = true;
				this.$nextTick(() => {
					this.$refs.countryInput.focus();
				});
			}
		},
		// 输入自定义区号
		handlecountryInput(val) {
			if (val === '') return;
			this.inputAreacodeList.push({
				id: val,
				name: val,
			});
			this.submitData.areacode = val;
			// this.checkPhone();
			this.inputAreacodeShow = false;
			this.countryVal = ''
			this.submitData.inputAreacode = '';
		},
		// 提交
		submitchangephone(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$http
						.post('/common/changephone', {
							"hrid": this.$store.getters.loginid,
							areacode: this.submitData.areacode,
							newphone: this.submitData.changephone,
							oldpasswd: this.submitData.opassword,
							newpasswd: this.submitData.npassword,
							code: this.submitData.code,
						})
						.then((response) => {
							if (response.data.success) {
								this.$message({
									type: 'success',
									message: this.$t('userinfoEditPhone.editPhoneSuccess')
								});

								this.$emit('editPhoneSuccess')
							} else {
								this.$alert(response.data.message, this.$t('common.alertPrompt'), {
									type: 'error',
									center: true
								})
							}
						})

				} else {
					// console.log('error submit!!');
					return false;
				}
			});
		},
		// 关闭
		closeDialog() {
			this.$emit('closeEditPhone')
		}
	}
}