export default {
	name: 'UserinfoSsoConfigPage',
	data() {
		return {
			// 表单数据
			submitData: {
				// 微软配置
				tenant_id: '',
				client_id: '',
				secret_1: '',
				secret_1_date: '',
				description: '',
			},
			originalData: {
				tenant_id: '',
				client_id: '',
				secret_1: '',
				secret_1_date: '',
				description: '',
			},
		}
	},
	computed: {
		// 是否有更改过表单
		isDiff() {
			let booleanList = []
			for (const key in this.originalData) {
				booleanList.push(this.originalData[key] == this.submitData[key])
			}
			return !booleanList.every(Boolean)
		},
		// 时间配置
		dateOptions() {
			let timeRange = '00:00:00 - 23:59:59'
			if (this.submitData.secret_1_date) {
				if (new Date(this.submitData.secret_1_date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
					timeRange = `${this.$utils.format(Date.now(), 'H:i:s')} - 23:59:59`
				}
			}
			return {
				disabledDate: (time) => {
					return time.getTime() < new Date().setHours(0, 0, 0, 0)
				},
				selectableRange: timeRange
			}
		},
	},
	mounted() {
		this.infoMicrosoft()
	},
	methods: {
		secretTimeChange(e) {
			if (e && new Date(e).getTime() < Date.now()) {
				this.submitData.secret_1_date = this.$utils.format(Date.now(), 'Y-m-d H:i:s')
			}
		},
		// 初始化微软配置
		infoMicrosoft() {
			this.$http.post('/common/oauthinfo', {
				hrid: this.$store.getters.loginid,
			}).then((response) => {
				if (response.data.success) {
					if (response.data.content) {
						this.submitData.tenant_id = response.data.content.tenant_id
						this.submitData.secret_1 = response.data.content.secret_1
						this.submitData.description = response.data.content.description
						this.submitData.secret_1_date = response.data.content.secret_1_date
						this.submitData.client_id = response.data.content.client_id
						// 复制数据
						this.originalData = JSON.parse(JSON.stringify(this.submitData))
					}
				} else {
					this.$alert(response.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}
			})
		},
		// 提交
		affirmMicrosoft() {
			// 判断是否跟原先的一样,不一样才发送请求了
			if (this.isDiff) {
				const publicKey = sessionStorage.getItem("publicKey")
				this.$http.post('/common/saveoauth', {
					hrid: this.$store.getters.loginid,
					tenant_id: this.submitData.tenant_id,
					client_id: this.submitData.client_id,
					// eslint-disable-next-line  
					secret_1: this.submitData.secret_1 ? sm2Encrypt(this.submitData.secret_1, publicKey, 0) : '',
					secret_1_date: this.submitData.secret_1_date || '',
					description: this.submitData.description,
				}).then((response) => {
					if (response.data.success) {
						this.infoMicrosoft()
						// showDialog(this.pageText.PROMPT, this.pageText.MICROSOFTSAVE, '', '', [this.pageText.OK]);
						this.$message({
							type: 'success',
							message: this.$t('userinfoSsoConfig.saveSuccess')
						});
						this.$emit('closeOauthConfig')
					} else {
						this.$alert(response.data.message, this.$t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
					}
				})
			}
		},
		// 取消修改
		cancelFn() {
			this.submitData = JSON.parse(JSON.stringify(this.originalData))
		}
	}
}