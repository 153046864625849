<template>
  <el-container class="container">
    <el-main class="main-wrapper">
      <el-form label-width="140px" :model="submitData" :rules="submitRules" ref="editPassForm">
        <el-form-item :label="$t('userinfoEditPass.configNewPass')" prop="newpassword">
          <el-input type="password" v-model="submitData.newpassword"></el-input>
        </el-form-item>
        <el-form-item :label="$t('userinfoEditPass.checkPassWord')" prop="checkpassword">
          <el-input type="password" v-model="submitData.checkpassword"></el-input>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer class="footer-wrapper" height="auto">
      <el-button @click="closeDialog">{{ $t('common.cancel') }}</el-button>
      <el-button type="primary" @click="submitDataFun('editPassForm')">{{ $t('common.save') }}</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
