<template>
  <web-layout :title="$t('sla.slaTitle')">
    <template>
      <el-row :gutter="20" class="auth-header">
        <el-col :span="10">
          <el-input :placeholder="$t('sla.slaSearchPlhld')" :title="query ? '' : $t('sla.slaSearchPlhld')" v-model="query" @keyup.enter.native="searchEvent" clearable>
            <el-button icon="el-icon-search" @click="searchEvent" slot="append"></el-button>
          </el-input>
        </el-col>
        <el-col :span="13" :offset="1" style="text-align: right">
          <el-button v-if="$store.getters.isOperate && !$store.getters.isDemo" @click="createAuthority" type="primary" icon="el-icon-add-solid">{{ $t('sla.slaAddTitle') }}</el-button>
        </el-col>
      </el-row>
      <div ref="tableWrapper">
        <el-table ref="tableRef" :data="slaList" style="width: 100%">
          <el-table-column prop="description" :label="$t('sla.slaDescLabel')"> </el-table-column>

          <el-table-column prop="feedbacktime" :label="$t('sla.slaFbtLabel')"> </el-table-column>
          <el-table-column :label="$t('common.operation')" width="120">
            <template slot-scope="scope" v-if="$store.getters.isOperate && !$store.getters.isDemo">
              <el-tooltip effect="light" :content="$t('common.edit')" placement="top">
                <el-button @click="editClick(scope.row)" type="text" icon="el-icon-edit"></el-button>
              </el-tooltip>
              <el-tooltip effect="light" :content="$t('common.delete')" placement="top">
                <el-button @click="deleteClick(scope.row)" type="text" icon="el-icon-delete"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <div slot="empty">
            <el-empty :description="$t('sla.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
          </div>
        </el-table>
        <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getSLAList"></pagination-box>
      </div>
    </template>

    <el-dialog :title="editData ? $t('sla.slaEditTitle') : $t('sla.slaAddTitle')" :visible.sync="dialogVisible" destroy-on-close width="650px">
      <sla-create-page v-if="dialogVisible" :editData="editData" @closeDialog="closeDialog" @createSuccess="createSuccess"></sla-create-page>
    </el-dialog>
  </web-layout>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
