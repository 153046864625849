<template>
  <web-layout :title="$t('webLayout.navSSO')">
    <template v-slot:extend>
      <el-button icon="el-icon-refresh-left" @click="cancelFn">{{ $t('common.cancel') }}</el-button>
      <el-button icon="el-icon-save" type="primary" @click="affirmMicrosoft">{{ $t('common.save') }}</el-button>
    </template>
    <div class="header_box">
      <h1 class="title">{{ $t('userinfoSsoConfig.microsoftConfiguration') }}</h1>
    </div>
    <el-container class="container">
      <el-main class="main-wrapper">
        <el-form label-width="165px" :model="submitData" ref="createForm">
          <el-form-item :label="$t('userinfoSsoConfig.tenantId')" prop="tenant_id">
            <el-input v-model="submitData.tenant_id" :placeholder="$t('userinfoSsoConfig.tenantId')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('userinfoSsoConfig.clientId')" prop="client_id">
            <el-input maxlength="100" v-model="submitData.client_id" :placeholder="$t('userinfoSsoConfig.clientId')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('userinfoSsoConfig.secret1')" prop="secret_1">
            <el-input v-model="submitData.secret_1" :placeholder="$t('userinfoSsoConfig.secret1')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('userinfoSsoConfig.secret1Date')" prop="secret_1_date">
            <el-date-picker style="width: 100%" v-model="submitData.secret_1_date" type="datetime" :picker-options="dateOptions" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" :placeholder="$t('userinfoSsoConfig.secret1Date')" @change="secretTimeChange"> </el-date-picker>
          </el-form-item>

          <el-form-item :label="$t('userinfoSsoConfig.description')" prop="description">
            <el-input v-model="submitData.description" :placeholder="$t('userinfoSsoConfig.description')"></el-input>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </web-layout>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
