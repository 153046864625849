<template>
  <el-container class="container">
    <el-main class="main-wrapper">
      <el-form label-width="170px" :model="submitData" :rules="submitRules" ref="editPhoneForm">
        <el-form-item :label="$t('userinfoEditPhone.configPhone')" prop="changephone">
          <el-input @input="handleNumInput" v-model="submitData.changephone" class="areacode-box">
            <el-select v-if="!inputAreacodeShow" v-model="submitData.areacode" slot="prepend" placeholder="" @change="handelChangeAreacode">
              <el-option :key="areacodeKey + areacodeItem.id" v-for="(areacodeItem, areacodeKey) in countryList" :label="areacodeItem.name" :value="areacodeItem.id"></el-option>
              <el-option :key="areacodeItem.id" v-for="areacodeItem in inputAreacodeList" :label="areacodeItem.name" :value="areacodeItem.id"></el-option>
              <el-option :label="$t('userinfoEditPhone.moreAreaCode')" :value="'more'"></el-option>
            </el-select>
            <el-input v-if="inputAreacodeShow" ref="countryInput" slot="prepend" v-model="countryVal" @change="handlecountryInput"></el-input>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('userinfoEditPhone.oldPassword')" prop="opassword">
          <el-input type="password" v-model="submitData.opassword"></el-input>
        </el-form-item>
        <el-form-item :label="$t('userinfoEditPhone.newPass')" prop="npassword">
          <el-input type="password" v-model="submitData.npassword"></el-input>
        </el-form-item>
        <el-form-item :label="$t('userinfoEditPhone.confirmNewPassword')" prop="repwd">
          <el-input type="password" v-model="submitData.repwd" :placeholder="$t('userinfoEditPhone.confirmNewPasswordPh')"></el-input>
        </el-form-item>

        <el-form-item :label="$t('userinfoEditPhone.verCode')" prop="imgCode">
          <el-col :span="17">
            <el-input v-model="submitData.imgCode"></el-input>
          </el-col>

          <el-col :span="4" :offset="1">
            <img :src="imgCodeSrc" class="code-img" />
          </el-col>
          <el-col :span="1" :offset="1">
            <div @click="getImageCode" style="cursor: pointer"><i class="el-icon-refresh-right"></i></div>
          </el-col>
        </el-form-item>

        <el-form-item :label="$t('userinfoEditPhone.phoneCode')" prop="code">
          <el-col :span="17">
            <el-input v-model="submitData.code" maxlength="6"></el-input>
          </el-col>

          <el-col :span="6" :offset="1">
            <el-button type="primary" :disabled="!isChangephoneSuccess || isSendPhone" style="width: 100%" @click="getCode">{{ sendCodeText }}</el-button>
          </el-col>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer class="footer-wrapper" height="auto">
      <el-button @click="closeDialog">{{ $t('common.cancel') }}</el-button>
      <el-button type="primary" :disabled="submitBtnDisabled" @click="submitchangephone('editPhoneForm')">{{ $t('common.save') }}</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
